<template>
    <div class="c-app" :style="appStyle" v-if="uid">
        <div style="z-index: 9999;" v-if="loading === false">
            <TheSidebar />
        </div>
        <div class="c-wrapper">
            <AppPushNotification ref="notification" :title="notificationModel.title" :text="notificationModel.text"
                :color="notificationModel.color" />
            <div style="margin-bottom: 7cap;" v-if="showHeader()">
                <header class="header-delivery text-center">
                    <template v-if="!hasSidebarToggle()">
                        <div @click="backOneStep()">
                            <img width="50px" src="/left-arrow.png"
                                style="position: fixed;left: 0;top: 0;margin: 1cap;">
                        </div>
                    </template>
                    <template v-else>
                        <i v-c-emit-root-event:toggle-sidebar-mobile class="fi fi-rr-menu-burger "
                            style="position: fixed;left: 0;top: 0;margin: 2.5cap;font-size: 23px;"></i>
                    </template>
                    <span class="mt-1 h4">{{ headerName }}</span>
                </header>
            </div>
            <main class="c-main">
                <div class="c-body">
                    <div style="width: 100%;" class="container-delivery">
                        <transition name="fade">
                            <div v-if="loading === true">
                                <div class="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div v-else-if="loading === false">
                                <div>
                                    <transition v-if="this.$route.name !== 'DeliveryMenulists'" name="fade"
                                        mode="out-in">
                                        <router-view></router-view>
                                    </transition>
                                    <router-view v-else></router-view>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </main>
        </div>
    </div>
    <div v-else style="background-color: #ffffff" class="min-vh-100">
        <div class="text-center p-5">
            <img style="margin-top: 200px" src="https://cdn-icons-png.flaticon.com/512/6951/6951727.png"
                class="img-fluid" width="50%" alt="" />
            <h6 class="mt-4 font-weight-bold">
                ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ
            </h6>
            <small class="text-muted">Please contact our staff for assistance</small>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebarOrder'
import '@/util/loader.css'
import '@/util/delivery.css'
import poscrm from '@/services/poscrm'
import AppPushNotification from '@/components/AppPushNotification.vue'
import firebaseInit from '@/firebaseInit'

export default {
    components: {
        TheSidebar,
        AppPushNotification
    },
    data() {
        return {
            loading: true,
            notificationModel: {
                title: '',
                text: '',
                color: '',
            },
            kitchenTime: {},
            timePeriod: {},
            inboxMessages: [],

        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        appStyle() {
            const routeNames = ['DeliveryUserAddresses', 'DeliveryOrderDetails'];
            return {
                backgroundColor: routeNames.includes(this.$route.name) ? '#eeeeee' : '#ffffff',
                overflowX: 'clip',
            };
        },
        token() {
            return localStorage.getItem('token') || null
        },
        uid() {
            let uid = this.$route.query.uid || localStorage.getItem('uid')
            if (uid) {
                localStorage.setItem('uid', uid)
            }
            return uid
        },
        shopObjectId() {
            let objectId = this.$route.query.shopObjectId || localStorage.getItem('shopObjectId')
            if (objectId) {
                localStorage.setItem('shopObjectId', objectId)
            }

            return objectId
        },
        headerName() {
            return this.getHeader(this.$route.name)
        },
        shop() {
            return this.shopLineOA.find(
                (detail) => detail.objectId === this.shopObjectId
            )
        },
    },
    beforeCreate() {
        this.$store.dispatch('getShopLineOA')
        localStorage.setItem('orderType', 'delivery')
    },
    created() {
        this.getOrderShopSetting()
        this.listenToInboxMessages()
    },
    methods: {
        listenToInboxMessages() {
            const profile = JSON.parse(localStorage.getItem('profile'));
            const profileObjectId = profile?.objectId;
            if (profileObjectId) {
                const inboxRef = firebaseInit.rdb.ref(`/profile/${profileObjectId}/inbox/`);

                // Log the Firebase reference path
                console.log("Firebase Reference Path:", `/profile/${profileObjectId}/inbox/`);

                // Retrieve all messages initially and log the inbox object
                inboxRef.once('value', (snapshot) => {
                    const messages = snapshot.val() || {};
                    // Check if there are messages
                    if (Object.keys(messages).length === 0) {
                        console.log("No messages in inbox.");
                    } else {
                        this.inboxMessages = Object.keys(messages).map(key => ({
                            id: key,
                            ...messages[key]
                        }));
                    }
                }).catch((error) => {
                    console.error("Error fetching initial messages:", error); // Log any potential errors
                });

                inboxRef.on('child_added', (snapshot) => {
                    const newMessage = snapshot.val();
                    if (!this.inboxMessages.find(msg => msg.id === snapshot.key)) {
                        this.inboxMessages.push({ id: snapshot.key, ...newMessage });

                        // Show notification when a new message arrives
                        this.showNotification('New Message', newMessage.body, 'info');
                    }
                });
                // Listen for changes in the inbox and log the updated message
                inboxRef.on('child_changed', (snapshot) => {
                    const updatedMessage = snapshot.val();
                    const index = this.inboxMessages.findIndex(msg => msg.id === snapshot.key);
                    if (index !== -1) {
                        this.$set(this.inboxMessages, index, { id: snapshot.key, ...updatedMessage });
                    }
                    this.showNotification('New Message', updatedMessage.body, 'info');
                });

                // Listen for removed messages and log the removed message
                inboxRef.on('child_removed', (snapshot) => {
                    const removedMessage = snapshot.val();
                    this.inboxMessages = this.inboxMessages.filter(msg => msg.id !== snapshot.key);
                });
            } else {
                console.error("Profile Object ID is missing or invalid.");
            }
        },
        backOneStep() {
            if (this.$route.query.redirect == 'payment') {
                this.$router.push('/delivery/history')
            } else {
                this.$router.go(-1)
            }
        },
        getToken() {
            return localStorage.getItem('token') || null
        },
        redirect() {
            if (this.shopLineOA.length === 0) {
                this.loading = true
            } else if (this.shopLineOA.length > 0) {
                this.loading = false
            }
        },
        hasSidebarToggle() {
            const routeName = this.$route.name;
            return (
                (routeName === 'DeliveryHistory' && !this.$route.query.source) ||
                routeName === 'DeliveryUser' ||
                routeName === 'DeliveryNotification'
            );
        },
        showNotification(title, text, color) {
            this.notificationModel = {
                color: color,
                title: title,
                text: text,
            }
            if (this.$refs.notification) {
                this.$refs.notification.pushNotification();
            }

        },
        showHeader() {
            if (this.$route.name == 'DeliveryUser' && this.getToken() == null) {
                return false
            }
            else if (this.loading === false && this.$route.name !== 'DeliveryMenulists' && this.$route.name !== 'DeliveryHome') {
                return true
            }
        },
        getHeader(pathName) {
            switch (pathName) {
                case 'DeliveryMyCart':
                    this.getOrderShopSetting()
                    return this.$t('cart')
                case 'DeliveryUser':
                    return this.$t('myAccount')
                case 'DeliveryHistory':
                    this.getOrderShopSetting()
                    return this.$t('purchaseHistory')
                case 'DeliveryUserAddresses':
                    return this.$t('deliveryAddress')
                case 'DeliveryTrackingOrder':
                    return this.$t('trackOrder')
                case 'DeliveryUserDetails':
                    return this.$t('editPersonalDetails')
                case 'DeliveryOrderDetails':
                    return this.$t('orderDetails')
                case 'DeliveryNotification':
                    return this.$t('notification')
                default:
                    return pathName
            }
        },
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    this.kitchenTime = res?.data?.data[0]?.kitchenTime || {}
                    localStorage.setItem('kitchenTime', JSON.stringify(this.kitchenTime))
                    const themeColor = res.data?.data[0]?.themeColor || '#29B46B'
                    $(':root').css('--bg-color-theme', themeColor);
                })
            } catch (error) {
                throw new Error(error)
            }
        },
    },
    watch: {
        shopLineOA() {
            this.redirect()
        },
        $route() {
            console.log(this.$route.name)
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
