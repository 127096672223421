import { render, staticRenderFns } from "./TheDeliveryContainer.vue?vue&type=template&id=57cab980&scoped=true"
import script from "./TheDeliveryContainer.vue?vue&type=script&lang=js"
export * from "./TheDeliveryContainer.vue?vue&type=script&lang=js"
import style0 from "./TheDeliveryContainer.vue?vue&type=style&index=0&id=57cab980&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57cab980",
  null
  
)

export default component.exports